import { isEqual } from "lodash";

export class UserInvitationModels {
  /**
   * @returns {{roleIds: *[], personId: null, emailMain: null, erpKey: null}}
   */
  initializeUserInvitation() {
    return {
      personId: null,
      emailMain: null,
      erpKey: null,
      roleIds: [],
    };
  }

  /**
   * @returns {{speakerJobTitle: null, lastName: null, zipCode: null, city: null, mobile: null, emailMain: null, isMember: null, isSpeaker: null, erpKey: null, itemText: null, firstName: null, companyId: null, speakerDescription: null, phone: null, street: null, emailSub: null, id: null, salutation: null}}
   */
  initializeSeminarParticipant() {
    return {
      city: null,
      companyId: null,
      emailMain: null,
      emailSub: null,
      erpKey: null,
      firstName: null,
      id: null,
      isMember: null,
      isSpeaker: null,
      itemText: null,
      lastName: null,
      mobile: null,
      phone: null,
      salutation: null,
      speakerDescription: null,
      speakerJobTitle: null,
      street: null,
      zipCode: null,
      personId: null,
    };
  }

  /**
   *
   * @param {object} userInvitationData
   * @param {string} userInvitationData.emailMain
   * @param {number[]} userInvitationData.roleIds
   * @param {number} userInvitationData.personId
   * @returns {{firstName: string, lastName: string, roleIds: number[], personId: number, salutation: string, email: string}}
   */
  userInvitationPayload(userInvitationData) {
    return {
      email: userInvitationData.emailMain,
      roleIds: userInvitationData.roleIds.map((role) => role.id),
      personId: userInvitationData.personId,
    };
  }

  /**
   * @param {object} userInvitation
   * @param {string | null} userInvitation.emailMain
   * @param {number[] | null} userInvitation.roleIds
   * @param {number | null} userInvitation.personId
   * @returns {boolean}
   */
  userInvitationWasEdited(userInvitation) {
    return !isEqual(userInvitation, this.initializeUserInvitation());
  }
}
