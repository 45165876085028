<template>
  <div>
    <v-row>
      <v-col cols="12" md="8" lg="10">
        <SeminarParticipantAutocomplete
          v-model="selectedSeminarParticipant"
          :selected-seminar-participant="seminarParticipant"
        />
      </v-col>

      <v-col cols="12" md="4" lg="2">
        <BaseButton :disabled="!seminarParticipantIsSelected" block @click="fillUserInvitation">
          {{ $t("USER_MANAGEMENT.USER_INVITATIONS.FORM.ACTIONS.TAKE") }}
        </BaseButton>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="mb-n3">
        <BaseTextField
          v-model="userInvitation.emailMain"
          label="USER_MANAGEMENT.USER_INVITATIONS.FORM.LABELS.EMAIL"
          :error="!!hasErrors('emailMain')"
          :error-messages="getErrors('emailMain')"
          required
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SeminarParticipantAutocomplete from "@components/FormElements/SeminarParticipantAutocomplete";
import formValidation from "@/mixins/formValidation";
import { UserInvitationModels } from "@/classes/userInvitation/UserInvitationModels";

export default {
  name: "UserInvitationInputForm",

  components: {
    SeminarParticipantAutocomplete,
  },

  props: {
    formIsLocked: {
      type: Boolean,
      required: false,
      default: false,
    },

    value: {
      type: Object,
      required: true,
      default: () => new UserInvitationModels().initializeUserInvitation(),
    },

    seminarParticipant: {
      type: Object,
      required: false,
      default: () => new UserInvitationModels().initializeSeminarParticipant(),
    },

    backendValidationErrors: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  mixins: [formValidation],

  data() {
    return {
      isLoading: false,
      userInvitation: this.value,
      selectedSeminarParticipant: this.seminarParticipant,
      validationErrors: this.backendValidationErrors,
    };
  },

  watch: {
    value: {
      deep: true,
      handler(value) {
        this.userInvitation = value;
      },
    },

    userInvitation: {
      deep: true,
      handler(value) {
        this.$emit("input", value);
      },
    },

    seminarParticipant: {
      deep: true,
      handler(value) {
        this.selectedSeminarParticipant = value;
      },
    },

    backendValidationErrors: {
      deep: true,
      handler(value) {
        this.validationErrors = value;
      },
    },
  },

  computed: {
    seminarParticipantIsSelected() {
      return !!this.selectedSeminarParticipant && !!this.selectedSeminarParticipant.id;
    },
  },

  methods: {
    fillUserInvitation() {
      if (!this.seminarParticipantIsSelected) return;

      this.userInvitation["emailMain"] = this.selectedSeminarParticipant.emailMain;
      this.userInvitation["personId"] = this.selectedSeminarParticipant.id;
    },
  },
};
</script>
