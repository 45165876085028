<template>
  <BaseAutoComplete
    v-model="seminarParticipant"
    :items="seminarParticipants"
    :search-input.sync="search"
    label="COMPONENTS.SEMINAR_PARTICIPANT_AUTOCOMPLETE.LABEL"
    item-text="itemText"
    return-object
    :hide-no-data="!search"
    :loading="isLoading"
    clearable
    @clearValue="initializeData"
  >
    <template #item="{ item }">
      {{ item.itemText }}
    </template>

    <template #selection="{ item }">
      {{ item.itemText }}
    </template>
  </BaseAutoComplete>
</template>

<script>
import { mapActions } from "vuex";
import { UserInvitationModels } from "@/classes/userInvitation/UserInvitationModels";
import { isEmpty } from "lodash";

export default {
  name: "SeminarParticipantAutocomplete",
  props: {
    value: {
      type: Object,
      required: true,
      default: () => new UserInvitationModels().initializeSeminarParticipant(),
    },

    required: {
      type: Boolean,
      default: false,
      required: false,
    },

    errorMessages: {
      type: Array,
      default: null,
    },

    selectedSeminarParticipant: {
      type: Object,
      required: false,
      default: () => new UserInvitationModels().initializeSeminarParticipant(),
    },
  },

  data() {
    return {
      isLoading: false,
      search: null,
      seminarParticipant: this.value,
      seminarParticipants: [],
    };
  },

  watch: {
    value(value) {
      this.salutation = value;
    },

    seminarParticipant: {
      deep: true,
      handler(value) {
        this.$emit("input", value);
      },
    },

    search(value) {
      if (isEmpty(value)) {
        this.initializeData();
      }

      this.getSeminarParticipants();
    },

    selectedSeminarParticipant: {
      deep: true,
      handler(value) {
        const selectedSeminarParticipant = {
          ...value,
          itemText: this.formatName(value),
        };
        this.seminarParticipant = selectedSeminarParticipant;
        this.seminarParticipants = [selectedSeminarParticipant];
      },
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    initializeData() {
      this.seminarParticipant = new UserInvitationModels().initializeSeminarParticipant();
      this.seminarParticipants = [];
    },

    formatName(seminarParticipant) {
      const { erpKey, firstName, lastName } = seminarParticipant;
      const showFirstName = firstName ? `${firstName} ` : "";

      return `${erpKey} - ${showFirstName} ${lastName}`;
    },

    async getSeminarParticipants() {
      if (!this.search) return;
      if (this.search.length <= 4) return;

      this.isLoading = true;

      try {
        const { data } = await this.$axios.userManagement.listPeople({
          search: this.search,
          isUserAssigned: false,
        });

        this.seminarParticipants = data.data.map((seminarParticipant) => ({
          ...seminarParticipant,
          itemText: this.formatName(seminarParticipant),
        }));
      } catch (err) {
        console.error(err);
        await this.notify({
          type: "error",
          message: "COMPONENTS.SEMINAR_PARTICIPANT_AUTOCOMPLETE.LOADING_ERROR",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
