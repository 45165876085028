<template>
  <ModuleSubPage fullWidth title="USER_MANAGEMENT.USER_INVITATIONS.CREATE.PAGE_TITLE">
    <BaseHasPermission :permissionNames="['invitation.create']">
      <v-card>
        <v-card-text>
          <user-invitation-input-form
            v-model="userInvitation"
            :seminar-participant="seminarParticipant"
            :backend-validation-errors="validationErrors"
            :form-is-locked="formIsLocked"
          />
        </v-card-text>

        <v-card-text class="mb-8">
          <assign-roles-table v-model="userInvitation.roleIds" />
        </v-card-text>

        <v-card-actions class="pb-4">
          <v-spacer />
          <BaseButtonTertiary @click="initializeData" :disabled="!dataWasEdited">
            {{ $t("USER_MANAGEMENT.USER_INVITATIONS.CREATE.ACTIONS.CLEAR") }}
          </BaseButtonTertiary>

          <BaseButton @click.prevent="create" color="primary" small>
            {{ $t("USER_MANAGEMENT.USER_INVITATIONS.CREATE.ACTIONS.CREATE") }}
          </BaseButton>
        </v-card-actions>
      </v-card>
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import UserInvitationInputForm from "@components/UserInvitation/UserInvitationInputForm";
import AssignRolesTable from "@components/AssignTables/Roles/AssignRolesTable";
import { mapActions } from "vuex";
import formValidation from "@mixins/formValidation";
import { UserInvitationModels } from "@/classes/userInvitation/UserInvitationModels";

export default {
  name: "UserInvitationCreate",

  components: {
    AssignRolesTable,
    UserInvitationInputForm,
    ModuleSubPage,
  },

  mixins: [formValidation],

  data() {
    return {
      isLoading: false,
      userInvitation: null,
      seminarParticipant: null,
    };
  },

  computed: {
    formIsLocked() {
      return !!this.userInvitation.personId;
    },

    dataWasEdited() {
      return new UserInvitationModels().userInvitationWasEdited(this.userInvitation);
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async changeRoute(data) {
      try {
        await this.$router.push({
          name: "management-user-invitation-show",
          params: { id: data.data.id },
        });
      } catch {
        return;
      }
    },

    async create() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.userManagement.storeUserInvitation(
          new UserInvitationModels().userInvitationPayload(this.userInvitation)
        );

        await this.notify({
          type: "success",
          message: "USER_MANAGEMENT.USER_INVITATIONS.CREATE.MESSAGES.SUCCESS",
        });

        await this.changeRoute(data);
      } catch (err) {
        console.error(err);
        this.setValidationErrors(err, false);
      } finally {
        this.isLoading = false;
      }
    },

    initializeData() {
      this.userInvitation = new UserInvitationModels().initializeUserInvitation();
      this.seminarParticipant = new UserInvitationModels().initializeSeminarParticipant();
    },
  },

  created() {
    this.initializeData();
  },
};
</script>
